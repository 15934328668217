import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestautomationTest } from '@shared/models/testautomation/TestautomationTest';
import { Subject } from 'rxjs';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';


@Component({
  selector: 'app-testautomation',
  templateUrl: './testautomation.component.html',
  styleUrl: './testautomation.component.scss'
})
export class TestautomationComponent implements OnInit, OnDestroy{

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();

    isLoading = false;
    testRuns: TestautomationTest[] = [];
    text: string;
    recentTestRun: TestautomationTest;
    activeTestId: number;
    testIsRunning: boolean;

    constructor(
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.text = 'Die Testläufe werden geladen. Bitte einen Moment Geduld ...';
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
                this.getTests();
            });
    }

    private getTests(): void {
        this.isLoading = true;
        this.testautomationService.getTestRuns(this.ROOT_URL)
            .subscribe(
                (testresponse: TestautomationTest[]) => {
                    this.testRuns = testresponse;
                    this.getRecentTestRun(testresponse);
                    console.log(testresponse);
                    this.getActiveTestRunId();
                },
                (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            );
    }

    private getRecentTestRun(testRuns:TestautomationTest[]): void {
        this.recentTestRun = testRuns.reduce((prev, current) => {
            let prevDate = new Date(prev.timeStamp);
            let currentDate = new Date(current.timeStamp);
            if (currentDate > prevDate) {
                return current;
            } else {
                return prev;
            }
        });
    }

    private getActiveTestRunId(){
        this.isLoading = true;
        this.testautomationService.getActiveTestRunId(this.ROOT_URL)
            .pipe(
                takeUntil(this.notifier)
            )
            .subscribe(
                (resp : number) =>{
                    this.activeTestId = resp;
                    this.checkForActiveTest(resp);
                    this.isLoading = false;
                },
                (error: any) => {
                    console.error(error);
                    this.isLoading = false;
                }
            )
    }

    private checkForActiveTest(testId: number){
        testId === null ? this.testIsRunning = false : this.testIsRunning = true;
    }

    ngOnDestroy() {
        this.notifier.complete();
    }
}
