import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestautomationService } from '@shared/services/testautomation/testautomation.service';
import { WarningComponent } from '@shared/components/warning/warning.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SwitchService } from '@shared/services/switch/switch.service';
import { ApiService } from '@shared/services/api/api.service';
import { Observable, Observer } from "rxjs";

@Component({
  selector: 'app-test-cancel',
  templateUrl: './test-cancel.component.html',
  styleUrl: './test-cancel.component.scss'
})
export class TestCancelComponent implements OnInit{
    @Input() testIsRunning: boolean;
    @Input() activeTestId: number;

    private ROOT_URL: string;
    private switchSetting: string;
    private notifier = new Subject();
    loadingSubtitle : string;
    isLoading: boolean;

    constructor(
        private modalService: NgbModal,
        private testautomationService: TestautomationService,
        private switchService: SwitchService,
        private apiService: ApiService
    ) {
    }

    ngOnInit(): void {
        this.switchService.switch
            .pipe(takeUntil(this.notifier))
            .subscribe(data => {
                this.switchSetting = data;
                this.ROOT_URL = this.apiService.getUrlBasedOnPermissionAndManualSwitch(
                    'BASE',
                    this.switchSetting
                );
            });
        this.loadingSubtitle = 'Der Testlauf wird abgebrochen. Bitte einen Moment Geduld ... ';
    }

    openConfirmationModal() {
        const modalRef = this.modalService.open(WarningComponent, {
            size: 'lg',
            windowClass: 'confirmation-modal',
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.componentInstance.message ='' +
            '<p>Sie sind im Begriff, den laufenden Test abzubrechen. ' +
            'In diesem Fall gehen alle Ergebnisse dieses Testlaufs verloren und es wird nichts gespeichert. ' +
            'Sind Sie sicher, dass Sie den Test abbrechen möchten?</p>'
        modalRef.componentInstance.closingOption = false;
        modalRef.result.then(
            result => {
                this.isLoading = true;
                this.testautomationService.cancelActiveTest(this.ROOT_URL,this.activeTestId,).subscribe(
                    response =>{
                        console.log(
                            'Test erfolgreich abgebrochen!', response
                        );
                        this.checkUntilTestStopped().subscribe(
                            (response: number) => {
                                console.log(
                                    'Test erfolgreich abgebrochen!'
                                );
                                this.isLoading = false;
                                window.location.reload();
                            },
                            (error: any) => {
                                console.error(error);
                            },
                            () => {
                                console.log('Observable abgeschlossen');
                            }
                        );
                    },
                    error => {
                        console.error(
                            'Test konnte nicht abgebrochen werden!', error
                        );
                    }
                );
        });
    }
    checkUntilTestStopped(): Observable<number> {
        return new Observable<number>((observer: Observer<number>) => {
            const makeBackendCall = () => {
                this.testautomationService.getActiveTestRunId(this.ROOT_URL).subscribe(
                    (response: number) => {
                        if (response !== null) {
                            setTimeout(() => {
                                makeBackendCall();
                            }, 3000);
                        } else {
                            observer.next(response);
                            observer.complete();
                        }
                    },
                    (error: any) => {
                        observer.error(error);
                    }
                );
            };
            makeBackendCall();
        });
    }
}
