<div class="smoketest-setting-detail vus-modal">
    <form [formGroup]="form">
        <div class="modal-header">
            <h3 class="modal-title">Baureihe {{mode === MODE.POST ? 'erstellen' : 'bearbeiten'}}</h3>
            <p class="modal-subtitle">Editieren Sie die FIN und laden Sie die dazugehörigen Daten aus VeDoc oder ändern Sie die Daten manuell.</p>
        </div>
        <div class="modal-body">
            <div class="margin-bottom-32">
                <div class="row">
                    <div class="col-6 d-flex">
                            <div class="col-6 p-0 margin-right-24">
                                <label for="fin">FIN*</label>
                                <input [ngClass]="{'is-invalid' : inputIsInvalid('fin')}" autocomplete="off" id="fin"
                                       class="form-control" type="text" formControlName="fin"
                                       aria-describedby="fin">
                            </div>
                            <div class="col-6 p-0 d-flex align-items-end">
                                <button class="btn btn-outline-primary" (click)="loadData()"
                                        [disabled]="form.controls.fin.invalid">
                                    <span>Daten automatisch laden</span>
                                </button>
                            </div>
                    </div>
                </div>
                <div class="row" *ngIf="inputIsInvalid('fin')">
                    <div class="col-12 validation-container">
                        <div *ngIf="form.get('fin').errors.required">Pflichtfeld!</div>
                        <div
                            *ngIf="(!form.get('fin').errors.required) && (form.get('fin').errors.minlength || form.get('fin').errors.maxlength)">
                            Die FIN muss exakt 17 Zeichen beinhalten!
                        </div>
                        <div *ngIf="(!form.get('fin').errors.required) && form.get('fin').errors.invalidVin">Die FIN ist
                            nicht valide!
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 padding-right-32">
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="baureihe">Baureihe*</label>
                            <input [ngClass]="{'is-invalid' : inputIsInvalid('baureihe')}"
                                   autocomplete="off" id="baureihe" class="form-control" type="text"
                                   formControlName="baureihe"
                                   aria-describedby="baureihe">
                            <div class="row" *ngIf="inputIsInvalid('baureihe')">
                                <div class="col-12 validation-container">
                                    <div *ngIf="form.get('baureihe').errors.required">Pflichtfeld!</div>
                                    <div
                                        *ngIf="(!form.get('baureihe').errors.required) && (form.get('baureihe').errors.minlength || form.get('baureihe').errors.maxlength)">
                                        Die Baureihe muss exakt 4 Zeichen beinhalten!
                                    </div>
                                    <div *ngIf="(!form.get('baureihe').errors.required) && form.get('baureihe').errors.invalidSeries">Die Baureihe ist
                                        nicht valide!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="ttz">TTZ*</label>
                            <input [ngClass]="{'is-invalid' : inputIsInvalid('ttz')}"
                                    autocomplete="off" id="ttz" class="form-control" type="text" formControlName="ttz"
                                   aria-describedby="ttz">
                            <div class="row" *ngIf="inputIsInvalid('ttz')">
                                <div class="col-12 validation-container">
                                    <div *ngIf="form.get('ttz').errors.required">Pflichtfeld!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="werkskennung">Werkskennung*</label>
                            <input [ngClass]="{'is-invalid' : inputIsInvalid('werkskennung')}"
                                   autocomplete="off"
                                   id="werkskennung" class="form-control" type="text" formControlName="werkskennung"
                                   aria-describedby="werkskennung">
                            <div class="row" *ngIf="inputIsInvalid('werkskennung')">
                                <div class="col-12 validation-container">
                                    <div *ngIf="form.get('werkskennung').errors.required">Pflichtfeld!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-0">
                    <div class="row">
                        <div class="col-12 form-group">
                            <label for="codes">Codeleiste*</label>
                            <textarea [ngClass]="{'is-invalid' : inputIsInvalid('codes')}"
                                      class="form-control codes-textarea" id="codes" name="codes" aria-describedby="codes"
                                      formControlName="codes"></textarea>
                            <div class="row" *ngIf="inputIsInvalid('codes')">
                                <div class="col-12 validation-container">
                                    <div *ngIf="form.get('codes').errors.required">Pflichtfeld!</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 d-flex">
                    <div class="margin-top-4">
                        <div>
                            Bitte auswählen:*
                        </div>
                        <div>
                            <mat-chip-listbox formControlName="zertArt" class="chip-group margin-top-8" role="group" aria-label="ETM-fähiges Fahrzeug">
                                <mat-chip-option [value]="ZertArt.ETM" class="chip-primary margin-right-12" id="ETM">ETM-fähig</mat-chip-option>
                                <mat-chip-option [value]="ZertArt.CEBAS" class="chip-primary" id="CEBAS">Nicht ETM-fähig</mat-chip-option>
                            </mat-chip-listbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-secondary" (mousedown)="cancel()">
                    <span>Abbrechen</span>
                </button>
                <button type="button" class="btn btn-primary" (click)="save()" [disabled]="form.invalid">
                    <span>Änderungen übernehmen</span>
                </button>
            </div>
        </div>
    </form>
</div>
