import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { menuPaths } from '@shared/constants/paths';
import { getSecurityMessageInformation } from '@shared/models/security-message';
import { DataPoolService } from '@shared/services/datapool/datapool.service';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SecurityService } from '@shared/services/security/security.service';

import { LoginService } from './core/login/login.service';
import { UnauthorizedModalComponent } from './core/unauthorized-modal/unauthorized-modal.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isAuthorized$ = this.securityService.isAuthorized;
    loginInProgress;

    constructor(
        private securityService: SecurityService,
        private modalService: NgbModal,
        private dataPoolService: DataPoolService,
        private loginService: LoginService,
        private localStorageService: LocalStorageService,
        private environmentService: EnvironmentService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loginInProgress = this.localStorageService.getItem(
            'loginProgressValidTill'
        );

        const hostname = window.location.hostname;
        const startIndex = hostname.indexOf('ui.');
        const endIndex = hostname.indexOf(
            '.vus.corpinter.net',
            startIndex + 'ui.'.length
        );

        const domainEnvironment = hostname.substring(
            startIndex + 'ui.'.length,
            endIndex
        );
        this.environmentService.setEnvironmentUrlViaSubdomain(
            domainEnvironment
        );

        if (!environment.production) {
            // Add Bearer token, if running in a non-production environment (local-dev)
            this.localStorageService.setItem(
                'zke-access-token',
                environment.accessToken
            );
            this.localStorageService.setItem(
                'zke-access-valid-till',
                new Date().getTime() + 12000
            );
            this.localStorageService.setItem('zke-access-expires-in', 1800);
            this.localStorageService.setItem(
                'zke-access-refresh-token',
                environment.refreshToken
            );
        }
        if (this.tokenIsValid()) {
            this.securityService.initialize().then(() => {
                this.securityService.isAuthorized.next(true);
                if (
                    !this.securityService.authorizationError.length &&
                    this.securityService.user
                ) {
                    this.loginService.startRefreshAccessTokenInterval();
                    this.dataPoolService.initializeDatapools();
                    this.router.navigate([
                        `${menuPaths.dataManagement.root}/${menuPaths.dataManagement.seriesData}`,
                    ]);
                } else {
                    const modalRef = this.modalService.open(
                        UnauthorizedModalComponent
                    );

                    const securityMessage = getSecurityMessageInformation(
                        this.securityService.authorizationError
                    );
                    modalRef.componentInstance.title = securityMessage.title;
                    modalRef.componentInstance.message =
                        securityMessage.message;
                    modalRef.componentInstance.showLogin =
                        securityMessage.showLogin;
                }
            });
        } else {
            if (
                !this.loginInProgress ||
                this.loginInProgress < new Date().getTime()
            ) {
                this.localStorageService.setItem(
                    'loginProgressValidTill',
                    new Date().getTime() + 30000
                );
                window.location.href = `${environment.commonApiUrl}/login-redirect`;
            }
        }
    }

    private tokenIsValid() {
        return (
            this.localStorageService.getItem('zke-access-token') &&
            this.localStorageService.getItem('zke-access-token').length &&
            this.localStorageService.getItem('zke-access-valid-till') >=
            new Date().getTime()
        );
    }
}
