<div class="vus-card-body">
    <div class="vus-container">
        <div class='vus-title title'>
        <span>Testlaufhistorie</span>
        </div>
        <ngx-datatable
            #dataTable
            class="material"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [rows]="testRuns">

            <ngx-datatable-column
                name="Testzeitpunkt"
                prop="timeStamp"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span *ngIf="value"> {{ value | date: "dd.MM.yy, HH:mm:ss" }}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
                name="Testdauer"
                prop="duration"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span>{{ formatTime(value) }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Testfälle bestanden"
                prop="successfulTestCases"
                [flexGrow]="3"
                [resizeable]="false"
            >
                <div class="status-box">
                    <ng-template
                        let-value="value"
                        let-row="row"
                        ngx-datatable-cell-template
                    >
            <span *ngIf="value">
            <fa-icon
                [style.color]="'#008351'"
                icon="circle"
            ></fa-icon>
                {{ value }} Testfälle bestanden</span>
                    </ng-template>
                </div>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="Testfälle nicht bestanden"
                prop="unsuccessfulTestCases"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
          <span *ngIf="value">
          <fa-icon
              [style.color]="'#bb1e10'"
              icon="circle"
          ></fa-icon>

              {{ value }} Nicht bestanden</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
                name="User"
                prop="username"
                [flexGrow]="3"
                [resizeable]="false"
            >

                <ng-template
                    let-value="value"
                    let-row="row"
                    ngx-datatable-cell-template
                >
                    <span *ngIf="value">{{ value }} </span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
                name="Aktionen"
                [flexGrow]="1"
                [sortable]="false"
                [resizeable]="false"
            >
                <ng-template
                    let-rowIndex="rowIndex"
                    let-row="row"
                    let-value="value"
                    ngx-datatable-cell-template
                >
                    <icon-button
                        [visible]="true"
                        toolTipDescription="Aktionen"
                        [iconSelector]="'eye'"
                        (click)="detail(row)"
                    ></icon-button>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

