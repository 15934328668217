<div class="vus-modal">
    <div class="modal-header close-top-right">
        <h3 class="modal-title">{{imageStage}} Image Detailansicht - {{image.imageTag}}</h3>
        <button *ngIf="!isVorabDataPool() && !isSerienDataPool() || (isSerienDataPool() && image.deployed === imageDeploymentStatus.READY)" type="button" class="btn close" aria-label="Close"
            (click)="dismiss()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <app-loading-spinner *ngIf="isPromotingImage" [text]="promoteLoadingText" [overlay]="true"></app-loading-spinner>
<div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)" class="uploadToolTabset zke-nav-tabs">
        <li [ngbNavItem]="fileType" *ngFor="let fileType of fileTypes" title="{{fileType.enumType}}" id="{{fileType.path}}">
            <a ngbNavLink class="zke-nav-link">{{fileType.enumType}}</a>
            <ng-template ngbNavContent>

                <ngx-datatable #dataTable class="material" [columnMode]="'force'" [rows]="rows" [draggable]="false"
                               [headerHeight]="50" [rowHeight]="'auto'" [footerHeight]="50" [sorts]="sortConfig"
                               [loadingIndicator]="!rows" [limit]="dataTableLimit">
                    <ngx-datatable-column *ngIf="showBaureiheColumn()" name="Baureihe" prop="baureihe" [flexGrow]="1"
                                          [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value">{{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Dateiname" prop="fileName" [flexGrow]="3" [canAutoResize]="true"
                                          [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value" class="long-text" placement="top" [ngbTooltip]="value"
                                  data-container="body" tooltipClass="note-tooltip">{{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Uploadzeitpunkt" prop="uploadedAt" [flexGrow]="2" [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value">{{ value | date: 'dd.MM.yy - HH:mm' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Erstellzeitpunkt" prop="createdAt" [flexGrow]="2" [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value">{{ value | date: 'dd.MM.yy - HH:mm' }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Kommentar" prop="note" [flexGrow]="3" [canAutoResize]="true"
                                          [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value" class="long-text" placement="top" [ngbTooltip]="value"
                                  data-container="body" tooltipClass="long-text-tooltip">{{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="User" prop="user" [flexGrow]="3" [canAutoResize]="true"
                                          [resizeable]="false">
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <span *ngIf="value" class="long-text" placement="top" [ngbTooltip]="value"
                                  data-container="body" tooltipClass="long-text-tooltip">{{ value }}</span>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Aktionen" prop="value" [flexGrow]="1" [canAutoResize]="false"
                                          [resizeable]="false">
                        <ng-template let-value="value" let-row="row" let-expanded="expanded"
                                     ngx-datatable-cell-template>
                            <div class="float-right icon-container">
                                <icon-button (action)="exportFile(row)" [visible]="true"
                                             [toolTipDescription]="'Datei downloaden'" [iconSelector]="'download'">
                                </icon-button>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                                     let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                                     let-isVisible="isVisible">
                            <custom-table-footer (setLimit)="setLimit($event)" [rowCount]="rowCount"
                                                 [element]="'Dateien'" [dataTableType]="'Modal'">
                            </custom-table-footer>

                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                             [pagerRightArrowIcon]="'datatable-icon-right'"
                                             [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                             [page]="curPage" [size]="pageSize" [count]="rowCount"
                                             [hidden]="!((rowCount / pageSize) > 1)" (change)="dataTable.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>
<div class="modal-footer" *ngIf="isVorabDataPool() || (isSerienDataPool() && image.deployed !== imageDeploymentStatus.READY)">
    <div class="d-flex justify-content-end">
        <button class="btn btn-secondary" (mousedown)="dismiss()">
            <span>Abbrechen</span>
        </button>
        <button *ngIf="isVorabDataPool()" class="btn btn-primary" [disabled]="!isPromotable() || isSecondModalActive"
            (click)="promoteImage()">Image zu Serie
            promoten</button>
        <button *ngIf="isSerienDataPool()" class="btn btn-primary" [disabled]="!isDeployable() || isSecondModalActive"
            (click)="toProductionImage()">
            <span>Image produktiv schalten</span>
        </button>
    </div>
</div>
